

























import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { CourseResponseCourses } from '../../../../types/typescript-axios/api';
import LabelStatus from '@/components/contents/label/LabelStatus.vue';
import LabelNew from '@/components/contents/label/LabelNew.vue';
import course from '@/store/course';

@Component({
  components: {
    LabelStatus,
    LabelNew,
  },
})
export default class CourseCard extends Vue {
  @Prop({ default: {} })
  private course!: CourseResponseCourses;

  @Emit()
  private dateFilter(date: string) {
    const d: Date = new Date(date);
    const dayArr = ['日', '月', '火', '水', '木', '金', '土'];
    const day = dayArr[d.getDay()];
    return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日（${day}）`;
  }
  @Emit()
  private brReplace(item: string) {
    let result = '';
    result = item.replace( /\n|\r\n|\r/g, '<br>' );
    return result;
  }

  private get labelDate(): Date | null {
    const matchResult: string[] | null = this.course.data2.date.match(/([0-9]{4})-([0-9]{2})-([0-9]{2})/);
    if (matchResult && matchResult[1] && matchResult[2] && matchResult[3]) {
      return new Date(Number(matchResult[1]), Number(matchResult[2]) - 1, Number(matchResult[3]));
    }
    return null;
  }

  private get title() {
    const $acf: any = this.course.data2.acf;
    if (typeof $acf.list_title !== 'undefined' && $acf.list_title !== '') {
      return this.brReplace($acf.list_title);
    } else {
      return this.course.data2.title.rendered;
    }
  }

  private get isLogin() {
    return this.$store.getters['auth/isLogin'];
  }

}
