import Vue from 'vue';
import VueRouter, { Route, RouteConfig, NavigationGuardNext } from 'vue-router';

Vue.use(VueRouter);

export const mail = {
  path: '/',
  component: () => import(/* webpackChunkName: "simpleLayouts" */ '../../layouts/SimpleLayouts.vue'),
  children: [
    {
      path: '/account/edit/mail',
      component: () => import(/* webpackChunkName: "editAccountParentMail" */ '../../views/account/edit/Mail.vue'),
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          name: 'EditAccountMailIndex',
          component: () => import(/* webpackChunkName: "EditAccountMailIndex" */ '../../views/account/edit/mail/Index.vue'),
          meta: { title: 'メールアドレス変更' },
        },
        {
          path: 'send',
          name: 'EditAccountMailSend',
          // tslint:disable-next-line:max-line-length
          component: () => import(/* webpackChunkName: "EditAccountMailSend" */ '../../views/account/edit/mail/Send.vue'),
          meta: { title: '本人確認メール送信完了 メールアドレス変更' },
        },
      ],
    },
    {
      path: '/account/edit/mail2',
      component: () => import(/* webpackChunkName: "editAccountParentMail" */ '../../views/account/edit/Mail.vue'),
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          name: 'EditAccountMailIndex',
          component: () => import(/* webpackChunkName: "EditAccountMailIndex" */ '../../views/account/edit/mail/Index2.vue'),
          meta: { title: 'メールアドレス変更 TEST' },
        },
        {
          path: 'send',
          name: 'EditAccountMailSend',
          // tslint:disable-next-line:max-line-length
          component: () => import(/* webpackChunkName: "EditAccountMailSend" */ '../../views/account/edit/mail/Send.vue'),
          meta: { title: '本人確認メール送信完了 メールアドレス変更' },
        },
      ],
    },
    {
      path: '/account/edit/mail/identification',
      component: () => import(/* webpackChunkName: "editAccountParentMail" */ '../../views/account/edit/mail/Identification.vue'),
      meta: { requiresAuth: true },
      children: [
        {
          path: ':key',
          name: 'EditAccountMailKey',
          component: () => import(/* webpackChunkName: "EditAccountMail" */ '../../views/account/edit/mail/identification/EditAccountMail.vue'),
          meta: { title: 'ご本人確認 メールアドレス変更' },
        },
        {
          path: ':key/complete',
          name: 'EditAccountMailComplete',
          component: () => import(/* webpackChunkName: "EditAccountMailComplete" */ '../../views/account/edit/mail/identification/EditAccountMailComplete.vue'),
          meta: { title: '変更完了 メールアドレス変更' },
        },
      ],
    },
  ],
};

export default mail;
